import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@core/services/http/base-http.service';
import { PlatformType } from '@core/data/type/platform.type';
import { Page } from '@core/data/model/page';
import { OrganizationPlatformResource } from '@core/data/model/organization-platform-resource';
import { PlatformResourceFilter } from '@core/data/model/dto/platform-resource-filter';
import { PlatformResourceType } from '@core/data/type/platform-resource.type';
import { PlatformResourceLinkDto } from '@core/data/model/dto/platform-resource-link-dto';
import { PlatformResourcePatchDto } from '@core/data/model/dto/platform-resource-patch.dto';

@Injectable()
export class PlatformResourceService extends BaseHttpService {

  constructor(protected override httpClient: HttpClient) {
    super('integration-service/platform-resources', httpClient);
  }

  findAllPlatformResources(
    platformType: PlatformType,
    includeLinks = false,
    page = 0,
    size = 30,
    sortKey = 'name',
    sortDir = 'asc'
  ): Observable<Page<OrganizationPlatformResource>> {
    return this.get<Page<OrganizationPlatformResource>>(`/${platformType}?page=${page}&size=${size}&sort=${sortKey},${sortDir}&includeLinks=${includeLinks}`);
  }

  searchPlatformResources(
    platformResourceTypes?: PlatformResourceType[],
    includeLinks = false,
    sortKey = 'name',
    sortDir = 'asc'
  ): Observable<OrganizationPlatformResource[]> {
    const filter: PlatformResourceFilter = {
      platformResourceTypes: platformResourceTypes,
      includeLinks: includeLinks
    };
    return this.post<OrganizationPlatformResource[]>(`/search?sort=${sortKey},${sortDir}`, filter);
  }

  linkPlatformResources(source: OrganizationPlatformResource, targets: OrganizationPlatformResource[]): Observable<OrganizationPlatformResource> {
    const dto = new PlatformResourceLinkDto(targets.map(target => target.id));
    return this.post<OrganizationPlatformResource>(`/${source.id}/links`, dto);
  }

  patchPlatformResource(source: OrganizationPlatformResource, dto: PlatformResourcePatchDto): Observable<OrganizationPlatformResource> {
    return this.patch<OrganizationPlatformResource>(`/${source.id}`, dto);
  }
}
